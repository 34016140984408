<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Elementos de compra</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small dark color="green"
              @click="exportExcel( elementos  , 'SALONES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dato_categoria = '', dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="elementos"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>


							    <template v-slot:item.actions="{ item }">
							    	<v-btn
							    		color="primary"
							    		x-small
							    		tile
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn
							    		color="error"
							    		x-small
							    		tile
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_elemento_mantenimiento }}</strong>
          </span>
        </v-card-title>

        <v-card-text>

          <v-autocomplete
            :items="categorias"
            v-model="editedItem.id_tipo_elemento"
            label="Categoría"
            filled
            dense
            item-text="tipo_requisicion"
            item-value="idtipo_requisiciones"
            @change="getComentariosCategorias()"
          ></v-autocomplete>

          <v-textarea
            v-if="this.editedIndex !== -1"
            v-model="editedItem.comentarios_categoria"
            label="Comentarios Categoria"
            filled
            rows="3"
            auto-grow
            readonly
          ></v-textarea>

          <v-textarea
            v-if="this.editedIndex === -1"
            v-model="dato_categoria"
            label="Comentarios Categoria"
            filled
            rows="3"
            auto-grow
            readonly
          ></v-textarea>

          <v-text-field
            v-model="editedItem.elemento_mantenimiento"
            label="* Elemento de compra"
            filled
            dense
          ></v-text-field>

          <v-text-field
            v-model="editedItem.no_servicio"
            label="Numero de servicio"
            filled
            dense
          ></v-text-field>

          <v-autocomplete
	          :items="planteles"
	          v-model="editedItem.id_plantel"
	          label="Plantel"
	          filled
	          dense
	          item-text="plantel"
	          item-value="id_plantel"
	      	></v-autocomplete>

          <v-textarea
            v-model="editedItem.comentarios"
            label="Comentarios"
            filled
            rows="3"
            auto-grow
          ></v-textarea>

        	<v-switch
        		label="¿Activo Si/No?"
            hide-details
        		v-model="editedItem.activo_sn">
        	</v-switch>

        	<div class="text-subtitle-1"><b>Fecha alta:</b> {{ editedItem.fecha_alta }}</div>
        	<div class="text-subtitle-1"><b>Fecha baja:</b> {{ editedItem.fecha_baja }}</div>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,

      editedItem: {
        id_elemento_mantenimiento:0,
				elemento_mantenimiento:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:1,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
        capacidad:0,
        id_plantel:'',
        id_usuario_ultimo_cambio:0,
        id_tipo_elemento:0,
        no_servicio:'',
      },

      defaultItem: {
        id_elemento_mantenimiento:0,
				elemento_mantenimiento:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:1,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
        capacidad:0,
        id_plantel:'',
        id_usuario_ultimo_cambio:0,
        id_tipo_elemento:0,
      },

      categorias:[],
      // Filtros:
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      elementos: [],
      info_categoria: '',
      dato_categoria: '',
      headers: [
        { text: 'ID'         , value: 'id_elemento_mantenimiento'    },
        { text: 'Elemento'   , value: 'elemento_mantenimiento'       },
        { text: 'No de servicio', value: 'no_servicio'               },
        { text: 'Categoría'  , value: 'tipo_requisicion'             },
        { text: 'Plantel'    , value: 'plantel'       },
        { text: 'Comentarios', value: 'comentarios'   },
        { text: 'Activo'     , value: 'activo_sn'     },
        { text: 'Alta'       , value: 'fecha_alta'    },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],
      planteles:[],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar elemento' : 'Editar elemento'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
      await this.getPlanteles()
      await this.getCategorias()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.elementos = []
        return this.$http.get('consultar.elemtos.compra').then(response=>{
        	this.elementos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.list.erp').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCategorias () {
        this.cargar = true
        this.categorias = []
        return this.$http.get('consultar.requisiciones').then(response=>{
          this.categorias = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.elementos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.elementos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('elementos.eliminar/' + this.editedItem.id_elemento_mantenimiento, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {

        this.editedItem.id_usuario_ultimo_cambio = this.getdatosUsuario.iderp

      	if(this.editedItem.horario == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('update.elemento.requisicion/' + this.editedItem.id_elemento_mantenimiento, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
        		this.close()
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('add.elemento.requisicion', this.editedItem).then(response=>{
        		this.close()
          	this.initialize()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      getComentariosCategorias () {
      	this.cargar = true
        this.info_categoria = ''
        this.dato_categoria = ''

          const payload = {
	    			idtipo_requisiciones : this.editedItem.id_tipo_elemento,
	    		}

        return this.$http.post('get.info.categoria', payload).then(response=>{
        	this.info_categoria = response.data
          this.dato_categoria = this.info_categoria.comentarios
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    },
  }
</script>


